/* @flow */
import getFragmentData from '../../../util/getFragmentData';
import { compose3 as compose } from '../../../util/functional/compose';
import HighlightVideo from './HighlightVideo.graphql';
import { createVideoThumbnail } from './factory';
import type { ResidentialDetailsGetters, ResidentialListingMedia } from '../../../models/lexa';
import type { SlideName } from '../../media-viewer';
import type { MediaIconClickType } from '../../../client/tracking/event/types';
import type { Thumbnail } from './types';

type RequiredData = {
    media: ResidentialListingMedia,
};

type AdaptedProps = {
    thumbnail: Thumbnail,
};

const dataAdapter =
    (openMediaViewer: (name: SlideName) => void) =>
    ({ media }: RequiredData) =>
    (highlightVideoIconClick: (type: MediaIconClickType) => void): AdaptedProps | null => {
        const { mainImage, videos } = media;

        return videos.length > 0
            ? {
                  thumbnail: createVideoThumbnail(openMediaViewer, highlightVideoIconClick, mainImage, videos[0]),
              }
            : null;
    };

const getListingData = (openMediaViewer: (name: SlideName) => void) =>
    compose(dataAdapter(openMediaViewer), getFragmentData(HighlightVideo), (data) => data.getListing());

export default (openMediaViewer: (name: SlideName) => void) => (data: ResidentialDetailsGetters) => {
    const listingData = getListingData(openMediaViewer)(data);
    const highlightVideoIconClick = data.getEventTracker().highlightVideoIconClick;
    return listingData(highlightVideoIconClick);
};

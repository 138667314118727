/* @flow */
import React, { Fragment } from 'react';
import type { Element } from 'react';
import { TemplatedLazyImage } from '../../../Image';
import '../styles.scss';
import styled from 'styled-components';
import { Card } from '@rea-group/construct-kit-core';

type Props = {|
    url: string,
    templatedUrl: string,
    price: ?string,
    title: ?string,
    propertyTypeDisplay?: string,
    primaryFeature: Element<*>,
    branding?: ?Element<*>,
|};

const StyledCard = styled(Card)`
    overflow: hidden;
    width: 310px;
    flex-shrink: 0;
    scroll-snap-align: center;
    margin: 0 10px 10px 10px;
    vertical-align: top;
    a {
        text-decoration: none;
    }
    &:first-child {
        margin-left: auto;
    }
    &:last-child {
        margin-right: auto;
    }
`;

const View = (props: Props) => {
    const { url, templatedUrl, price, title, propertyTypeDisplay, primaryFeature, branding } = props;
    const renderContent = () => (
        <Fragment>
            <TemplatedLazyImage
                className="footer-carousel-card__main-image"
                templatedUrl={templatedUrl}
                size="310x175"
                avif={true}
                options="smart,extend"
                alt=""
            />
            {branding}
            <div className="footer-carousel-card__content">
                <p className="footer-carousel-card__price">{price}</p>
                <p className="footer-carousel-card__title">{title}</p>
                {propertyTypeDisplay && <p className="footer-carousel-card__property-type">{propertyTypeDisplay}</p>}
                {primaryFeature}
            </div>
        </Fragment>
    );

    return (
        <StyledCard>
            <a href={url}>{renderContent()}</a>
        </StyledCard>
    );
};

export default View;

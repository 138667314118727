/* @flow */
import { SlideNames, type SlideName } from '../../../media-viewer';
import type { Button, Link } from '../types';
import type { Video, ExternalVideo } from '../../../../models/lexa/videoType';
import type { MediaIconClickType } from '../../../../client/tracking/event/types';

export default (
        openMediaViewer: (name: SlideName) => void,
        floorplansToursIconClick: (type: MediaIconClickType) => void
    ) =>
    (video: Video): Link | Button => {
        const asMediaViewerButton = () => ({
            type: 'Button',
            className: 'video',
            display: 'Video',
            action: () => {
                openMediaViewer(SlideNames.VIDEO);
                floorplansToursIconClick('video');
            },
        });

        const asLink = (externalVideo: ExternalVideo) => ({
            type: 'Link',
            className: 'video',
            display: 'Video',
            href: externalVideo.href,
            action: () => floorplansToursIconClick('video'),
        });

        return video.__typename === 'YouTubeVideo' ? asMediaViewerButton() : asLink(video);
    };

/* @flow */
import React from 'react';
import './styles.scss';
import type { GetRelatedProperties, GetResidentialListing } from '../../models/lexa';
import { RelatedPropertiesCarousel } from '../details/footer-carousel';
import type { Channel } from '../../models/Channel';
import { ResponsiveLinkButton } from '../buttons';
export type Props = {
    name: string,
    href: string,
    description: string,
};

export type ViewProps = Props & {
    data: GetResidentialListing & GetRelatedProperties,
    channel: Channel,
};

export default (props: ViewProps) => {
    const { data, channel, name, description, href } = props;
    return (
        <div className="related-properties-footer">
            <div className="related-properties-footer__content">
                <div className="related-properties-footer__name">{name}</div>
                <div className="related-properties-footer__description">{description}</div>
                <ResponsiveLinkButton href={href}>View agency profile</ResponsiveLinkButton>
            </div>
            <RelatedPropertiesCarousel data={data} channel={channel} />
        </div>
    );
};

/* @flow */
import React from 'react';
import './styles.scss';
import styled from 'styled-components';
import type { Link, Button } from './types';
import SectionHeading from '../section-heading';
import { ThreedeeLg, ExternalLinkLg, FloorplanLg, VideoLg, paletteHelper } from '@rea-group/construct-kit-core';

type Props = {
    links: ?Array<Link | Button>,
};

type ListItemProps = {
    item: Link | Button,
};

const StyledIcon = styled.span`
    color: ${paletteHelper('textPrimary')};
`;

const ListItem = ({ item }: ListItemProps) => {
    const Icons = {
        floorplan: <FloorplanLg />,
        video: <VideoLg />,
        tour: <ThreedeeLg />,
        external: <ExternalLinkLg />,
    };
    return (
        <li className="floorplans-tours__item">
            {item.type === 'Link' ? (
                <a
                    className="floorplans-tours__link"
                    href={item.href}
                    onClick={item.action}
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                >
                    <StyledIcon className={`floorplans-tours__${item.className}`} aria-label={item.className}>
                        {Icons[item.className]}
                    </StyledIcon>
                    <span className="floorplans-tours__text">{item.display}</span>
                </a>
            ) : (
                <button className="floorplans-tours__link" onClick={item.action}>
                    <StyledIcon className={`floorplans-tours__${item.className}`} aria-label={item.className}>
                        {Icons[item.className]}
                    </StyledIcon>
                    <span className="floorplans-tours__text">{item.display}</span>
                </button>
            )}
        </li>
    );
};

const View = ({ links }: Props) => {
    return links ? (
        <div className={`floorplans-tours`}>
            <SectionHeading>Floorplans and tours</SectionHeading>
            <ul className="floorplans-tours__list">
                {links.map((item, index) => {
                    return <ListItem item={item} key={index} />;
                })}
            </ul>
        </div>
    ) : null;
};

export default View;

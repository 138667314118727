/* @flow */
import React, { useContext } from 'react';
import View from './View';
import adaptLexaData from './adaptLexaData';
import type { GetResidentialListing, GetRelatedProperties } from '../../models/lexa';
import ChannelContext from '../../util/ChannelContext';

type DataType = {|
    data: GetResidentialListing & GetRelatedProperties,
|};

export default ({ data }: DataType) => {
    const channel = useContext(ChannelContext);
    const adaptedData = adaptLexaData(channel)(data);
    if (adaptedData) {
        return <View {...adaptedData} data={data} channel={channel} />;
    }
    return null;
};

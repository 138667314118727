/* @flow */

import { compose3 as compose } from '../../../util/functional/compose';
import type { ResidentialListing, ResidentialDetailsGetters } from '../../../models/lexa';
import getFragmentData from '../../../util/getFragmentData';
import type { AdaptedProps } from './types';
import type { PropertyFeature, PropertyFeatureValue } from '../../../models/lexa/propertyFeature';
import PropertyFeatures from './PropertyFeatures.graphql';

const separateValueTypes = (value: PropertyFeatureValue): string => {
    if (value.__typename === 'MeasurementFeatureValue') {
        return `${value.displayValue}${value.sizeUnit.displayValue}`;
    }
    return `${value.displayValue}`;
};

const createValue = (feature: PropertyFeature): ?string => {
    return feature.value ? separateValueTypes(feature.value) : null;
};

const dataAdapter = ({ propertyFeatures }: ResidentialListing): AdaptedProps => {
    const mappedFeatures = propertyFeatures.map((feature) => {
        return {
            id: feature.id,
            displayLabel: feature.displayLabel,
            value: createValue(feature),
        };
    });

    return {
        features: mappedFeatures,
    };
};

const getListingData = compose(dataAdapter, getFragmentData(PropertyFeatures), (data) => data.getListing());

export default (data: ResidentialDetailsGetters): AdaptedProps => getListingData(data);

/* @flow */
import { type SlideName, SlideNames } from '../../../media-viewer';
import type { MediaIconClickType } from '../../../../client/tracking/event/types';
import type { LexaImage } from '../../../../models/lexa';
import type { Video, ExternalVideo } from '../../../../models/lexa/videoType';
import type { Thumbnail } from '../types';
import createPicture from '../../../../models/Picture';

const createVideoThumbnail = (
    openMediaViewer: (name: SlideName) => void,
    highlightVideoIconClick: (type: MediaIconClickType) => void,
    mainImage: LexaImage,
    video: Video
): Thumbnail => {
    const asYoutubeLink = () => ({
        image: createPicture(mainImage.templatedUrl),
        action: () => {
            openMediaViewer(SlideNames.VIDEO);
            highlightVideoIconClick('video');
        },
        label: 'videos available',
        altText: 'video',
    });

    const asExternalLink = (externalVideo: ExternalVideo) => ({
        image: createPicture(mainImage.templatedUrl),
        action: () => {
            window.open(externalVideo.href, '_blank', 'noopener,noreferrer');
            highlightVideoIconClick('video');
        },
        label: 'videos available',
        altText: 'externalVideo',
    });

    return video.__typename === 'YouTubeVideo' ? asYoutubeLink() : asExternalLink(video);
};

export default createVideoThumbnail;

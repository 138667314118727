import {
    ArrowDownTwoLg,
    ArrowUpTwoLg,
    ChatLg,
    Dialog,
    ExternalLinkMd,
    H2,
    Inline,
    Link,
    Modal,
    MonitorLg,
    P,
    Stack,
    VideoLg,
} from '@rea-group/construct-kit-core';
import React, { ReactNode } from 'react';
import { ViewProps } from './types';
import { FeatureIcon } from '../../../types';
import { SectionTitle, SecondaryText, GreyCard, SlimDivider } from './styles';

const speedIcons: { [Property in FeatureIcon]: React.ReactNode } = {
    video: <VideoLg />,
    chat: <ChatLg />,
    desktop: <MonitorLg />,
};

const Heading = ({ title, description }: { title: string; description: string }) => (
    <div>
        <SectionTitle>{title}</SectionTitle>
        <P>{description}</P>
    </div>
);

const SpeedDetails = ({ title, display, icon }: { title: string; display: string; icon: ReactNode }) => (
    <Inline gap="small" grow={false}>
        {icon}
        <div>
            <P>{title}</P>
            <SecondaryText variant="body02">{display}</SecondaryText>
        </div>
    </Inline>
);

export default function View({ onClose, title, speed, connection }: ViewProps) {
    return (
        <Modal opened={true} getAppElement={() => '#argonaut-wrapper'} onRequestClose={onClose}>
            <Dialog onClose={onClose} header={<H2>{title}</H2>} maxWidth="33.75rem">
                <Stack inset="medium" gap="medium">
                    {speed && (
                        <React.Fragment>
                            <Heading title={speed.title} description={speed.description} />
                            <GreyCard $gap="small" $columns={1} $padding="medium">
                                {speed.supportFeatures.map(({ featureIcon, display }) => (
                                    <React.Fragment key={featureIcon}>
                                        {speedIcons[featureIcon]}
                                        <P>{display}</P>
                                    </React.Fragment>
                                ))}
                            </GreyCard>
                            <P>{speed.speedMetrics.title}</P>
                            <GreyCard $gap="medium" $columns={2} $padding="small">
                                <SpeedDetails
                                    title={speed.speedMetrics.downloadSpeed.title}
                                    display={speed.speedMetrics.downloadSpeed.display}
                                    icon={<ArrowDownTwoLg />}
                                />
                                <SpeedDetails
                                    title={speed.speedMetrics.uploadSpeed.title}
                                    display={speed.speedMetrics.uploadSpeed.display}
                                    icon={<ArrowUpTwoLg />}
                                />
                            </GreyCard>
                            <Link href={speed.speedOptionsCta.link} icon={<ExternalLinkMd />} iconPlacement="right">
                                {speed.speedOptionsCta.title}
                            </Link>
                            <SecondaryText variant="caption">{speed.legalDisclaimer}</SecondaryText>
                            <SlimDivider />
                        </React.Fragment>
                    )}
                    <Heading title={connection.title} description={connection.description} />
                    <Link href={connection.technologyCta.link} icon={<ExternalLinkMd />} iconPlacement="right">
                        {connection.technologyCta.title}
                    </Link>
                    <SlimDivider />
                    <SecondaryText variant="caption">{connection.legalDisclaimer}</SecondaryText>
                </Stack>
            </Dialog>
        </Modal>
    );
}

/* @flow */

import { compose3 as compose } from '../../../util/functional/compose';
import getFragmentData from '../../../util/getFragmentData';
import type { GetBuyResidentialListing, BuyResidentialListing, ProjectProfile } from '../../../models/lexa';
import type { CardItem } from './types';
import SiblingChildListingsCarousel from './SiblingChildListingsCarousel.graphql';

type AdaptedProps = Array<CardItem<GetBuyResidentialListing>>;
type RequiredProps = {
    id: string,
    parent: ?ProjectProfile,
};

const dataAdapter = ({ id, parent }: RequiredProps): ?AdaptedProps => {
    const cid = 'pdp|buy:pdp:footer-child-listing-card';
    if (!parent || parent.childListings.results.length <= 1) return null;
    return parent.childListings.results
        .filter((child) => child.id !== id)
        .slice(0, 3)
        .map((child) => {
            return {
                id: child.id,
                url: `${child._links.canonical.path}?cid=${cid}`,
                templatedUrl: child.media.mainImage.templatedUrl,
                price: child.price.display,
                title: child.title,
                propertyTypeDisplay: child.propertyType.display,
                listing: {
                    getListing: () => child,
                },
            };
        });
};

export default compose<GetBuyResidentialListing, BuyResidentialListing, RequiredProps, ?AdaptedProps>(
    dataAdapter,
    getFragmentData(SiblingChildListingsCarousel),
    (data) => data.getListing()
);

/* @flow */

import { type Channel } from '../../../models/Channel';
import type {
    RelatedProperties,
    GetResidentialListing,
    GetRelatedProperties,
    ResidentialListing,
} from '../../../models/lexa';
import type { AdaptedRelatedProperties } from './types';

const dataAdapter =
    (channel: Channel) =>
    (data: GetResidentialListing & GetRelatedProperties): ?AdaptedRelatedProperties => {
        const relatedProperties: ?RelatedProperties = data.getRelatedProperties();
        const { viewConfiguration }: ResidentialListing = data.getListing();
        const shouldRenderBranding =
            viewConfiguration && viewConfiguration.details && viewConfiguration.details.brandingOnRelatedListings;
        const items = relatedProperties && relatedProperties.items;
        const trackingTag = `sourcePage=rea:${channel}:pdp&sourceElement=related-properties`;
        if (items && items.length > 0) {
            return {
                shouldRenderBranding,
                items: items.slice(0, 3).map((item) => {
                    const { id, _links, media, price, address } = item;
                    const fullAddress = address.display.shortAddress
                        ? `${address.display.shortAddress}, ${address.suburb}`
                        : '';
                    return {
                        id,
                        url: `${_links.canonical.path}?${trackingTag}`,
                        templatedUrl: media.mainImage.templatedUrl,
                        price: price.display,
                        title: fullAddress,
                        listing: {
                            getListing: () => item,
                        },
                    };
                }),
            };
        }
        return null;
    };

export default dataAdapter;

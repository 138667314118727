import { NbnSummary } from './types';

export const mockNbnSummary: NbnSummary = {
    title: 'Internet availability',
    speedDescription: {
        speedIcon: 'amazing',
        title: 'Amazing speed',
        display: 'Download up to 1000Mbps',
    },
    connectionDescription: {
        connectionStatusIcon: 'available',
        title: 'nbn® FTTP',
        display: 'Connected to nbn® Fibre to the Premises',
    },
    learnMore: {
        title: 'Learn more',
    },
};

/* @flow */
import React, { Fragment } from 'react';
import type { Picture } from '../../../../models/Picture';
import styled from 'styled-components';
import { Button } from '@rea-group/construct-kit-core';
import { PlayVideoOverlay } from '../overlay';

type Props = {
    altText: string,
    image: Picture,
    action: () => void,
    label: string,
};

const StyledButton = styled(Button)`
    height: 248px;
    width: 100%;
    border: none;
    border-radius: var(--ck-radius-large);
    position: relative;
    overflow: hidden;
    cursor: pointer;

    img {
        top: 50%;
        left: 50%;
        position: absolute;
        transform: translate3d(-50%, -50%, 0);
        width: 100%;

        @supports (object-fit: cover) {
            position: static;
            transform: none;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
`;

export default ({ image, action, label, altText }: Props) => {
    const renderThumbnailContent = () => (
        <Fragment>
            <img src={image.ofSize('800x600')} alt={altText} />
            <PlayVideoOverlay />
        </Fragment>
    );

    return (
        <StyledButton onClick={action} aria-label={label} variant="link-primary">
            {renderThumbnailContent()}
        </StyledButton>
    );
};

/* @flow */
import React from 'react';
import type { Element as ReactElement } from 'react';
import styled from 'styled-components';
import { paletteHelper } from '@rea-group/construct-kit-core';

const HighLightVideoOverlay = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    background: rgba(32, 40, 46, 0.42);
`;

const PlayVideoIcon = styled.div`
    position: relative;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    background-color: ${paletteHelper('backgroundPrimaryLow')};
    width: ${(props) => props.theme.spacing.twoExtraLarge};
    height: ${(props) => props.theme.spacing.twoExtraLarge};

    &:after {
        content: '';
        position: absolute;
        top: ${(props) => props.theme.spacing.small};
        left: ${(props) => props.theme.spacing.medium};
        width: 0;
        height: 0;
        border-top: ${(props) => props.theme.spacing.extraSmall} solid transparent;
        border-bottom: ${(props) => props.theme.spacing.extraSmall} solid transparent;
        border-left: 14px solid rgb(82, 82, 82);
    }
`;

export const PlayVideoOverlay = (): ReactElement<*> => (
    <HighLightVideoOverlay>
        <PlayVideoIcon />
    </HighLightVideoOverlay>
);

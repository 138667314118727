/* @flow */

import getFragmentData from '../../../util/getFragmentData';
import { compose3 as compose } from '../../../util/functional/compose';
import type { ResidentialDetailsGetters, ResidentialListingMedia } from '../../../models/lexa';
import type { SlideName } from '../../media-viewer';
import type { Link, Button } from './types';
import type { MediaIconClickType } from '../../../client/tracking/event/types';
import { toVideoItem, threeDimensionalTourItem, floorplanItem, toExternalLinkItem } from './factory';
import FloorPlansTours from './FloorPlansTours.graphql';

const limitMedia = <D>(array: Array<D>): ({ to: (limit: number) => Array<D> }) => ({
    to: (limit) => {
        return array.slice(0, limit);
    },
});

type RequiredData = {
    media: ResidentialListingMedia,
};

type AdaptedProps = {
    links: ?Array<Link | Button>,
};

const dataAdapter =
    (openMediaViewer: (name: SlideName) => void) =>
    ({ media }: RequiredData) =>
    (floorplansToursIconClick: (type: MediaIconClickType) => void): AdaptedProps => {
        const { floorplans, threeDimensionalTours, videos, externalLinks } = media;

        const videoItem = limitMedia(videos).to(1).map(toVideoItem(openMediaViewer, floorplansToursIconClick));
        const threeDimensionalTour = limitMedia(threeDimensionalTours)
            .to(1)
            .map(threeDimensionalTourItem(openMediaViewer, floorplansToursIconClick));
        const floorplan = limitMedia(floorplans).to(1).map(floorplanItem(openMediaViewer, floorplansToursIconClick));
        const externalLinkItems = limitMedia(externalLinks).to(2).map(toExternalLinkItem(floorplansToursIconClick));

        const links = videoItem.concat(threeDimensionalTour).concat(floorplan).concat(externalLinkItems);

        return { links: links.length ? limitMedia(links).to(4) : null };
    };

const getListingData = (openMediaViewer: (name: SlideName) => void) =>
    compose(dataAdapter(openMediaViewer), getFragmentData(FloorPlansTours), (data) => data.getListing());

export default (openMediaViewer: (name: SlideName) => void) => (data: ResidentialDetailsGetters) => {
    const listingData = getListingData(openMediaViewer)(data);
    const floorplansToursIconClick = data.getEventTracker().floorplansToursIconClick;
    return listingData(floorplansToursIconClick);
};

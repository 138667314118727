/* @flow */
import { useEffect, useContext } from 'react';
import { scrollTo } from '../../util/DOMUtils';
import StickyBrandingContext from './StickyBrandingContext';

const useScrollToElementWithHashUrl = (
    hashUrl: string,
    elementRef: () => ?Element,
    triggerOnMount: boolean = false
) => {
    const { stickyBrandingOffset } = useContext(StickyBrandingContext);
    useEffect(() => {
        const handleHashChange = () => {
            if (location.hash === hashUrl) {
                const scrollToElement = elementRef();
                if (scrollToElement) {
                    setTimeout(() => {
                        scrollTo(scrollToElement, stickyBrandingOffset);
                    }, 500);
                }
            }
        };
        if (triggerOnMount) {
            handleHashChange();
        }
        window.addEventListener('hashchange', handleHashChange);
        return () => window.removeEventListener('hashchange', handleHashChange);
    }, [hashUrl, elementRef, stickyBrandingOffset, triggerOnMount]);
};

export default useScrollToElementWithHashUrl;

import { H3, spacingHelper, paletteHelper, P, Divider } from '@rea-group/construct-kit-core';
import styled from 'styled-components';

export const SectionTitle = styled(H3).attrs({ variant: 'title03' })`
    margin-bottom: ${spacingHelper('extraSmall')};
`;

type SpeedGridProps = {
    $padding: 'medium' | 'small';
    $gap: 'medium' | 'small';
    $columns: 1 | 2;
};

export const GreyCard = styled.div<SpeedGridProps>`
    background-color: ${paletteHelper('backgroundSecondaryLow')};
    border-radius: ${({ theme }) => theme.border.radius.large};
    display: grid;
    align-items: center;
    grid-template-columns: ${({ $columns, theme }) =>
        $columns === 1 ? `${theme.spacing.large} 1fr` : 'repeat(auto-fill, minmax(12rem, 1fr))'};
    gap: ${({ $gap, theme }) => theme.spacing[$gap]};
    padding: ${({ $padding, theme }) => theme.spacing[$padding]};
`;

export const SecondaryText = styled(P)`
    color: ${paletteHelper('textSecondary')};
`;

export const SlimDivider = styled(Divider)`
    margin: 0;
`;

import { NbnDetails } from './types';

export const mockNbnDetails: NbnDetails = {
    title: 'nbn® connection',
    speed: {
        title: 'nbn® speed option',
        description: 'The highest speed option available at this property is nbn® Home Ultrafast, which is ideal for:',
        supportFeatures: [
            {
                featureIcon: 'video',
                display: 'Cinema-quality streaming on 10 or more devices at the same time.',
            },
            {
                featureIcon: 'desktop',
                display: 'Top-quality online and cloud gameplay even when others are online.',
            },
            {
                featureIcon: 'chat',
                display: 'Top-quality video calls even when others are online or downloading files.',
            },
        ],
        speedMetrics: {
            title: 'This speed option has amazing speeds:',
            downloadSpeed: {
                title: '1000Mbps download',
                display: 'Download a 10GB file within 2 minutes.',
            },
            uploadSpeed: {
                title: '400Mbps upload',
                display: 'Upload a 1GB file within 4 minutes.',
            },
        },
        speedOptionsCta: {
            title: 'Learn more about speed options',
            link: 'https://www.nbnco.com.au/campaigns/more-speed',
        },
        legalDisclaimer:
            'Your experience will depend on your nbn access technology, internet provider, plan and equipment. Speak to internet providers to find a provider and plan that suit you.',
    },
    connection: {
        title: 'nbn® Connection type',
        description: 'This property is connected to the nbn network. It’s ready to connect to the internet with nbn.',
        technologyCta: {
            title: 'Learn more about nbn technology',
            link: 'https://www.nbnco.com.au/learn/network-technology',
        },
        legalDisclaimer:
            'in displaying this information, realestate.com.au relies on information supplied by nbn. Connection data presented may change from time to time, may not be accurate, complete, up to date, and may not have been validated for accuracy.',
    },
};

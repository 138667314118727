/* @flow */
import React from 'react';
import View from './View';
import type { ResidentialDetailsGetters } from '../../../models/lexa';
import adaptLexaData from './adaptLexaData';

type Props = {
    data: ResidentialDetailsGetters,
};

const PropertyFeatures = ({ data }: Props) => {
    return <View {...adaptLexaData(data)} />;
};

export default PropertyFeatures;

// import { GetComponentGetters } from '../../../types';
import compose from '../../util/functional/typescript/compose';
import { mockNbnSummary } from './mockNbnSummary';
import { AdaptedProps, NbnSummary } from './types';

const dataAdapter = ({ component }: { component: NbnSummary | null }): AdaptedProps => {
    return !component ? null : component;
};

// const adaptLexaData = compose(dataAdapter, (data: GetComponentGetters) => ({
//     component: data.getComponent('NbnSummaryComponent'),
// }));

const adaptLexaData = compose(dataAdapter, () => ({
    component: mockNbnSummary,
}));

export default adaptLexaData;

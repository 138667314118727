import React from 'react';
import View from './View';
import adaptLexaData from './adaptLexaData';
import { Props } from './types';
// @ts-expect-error use flow in ts temperarily
import { FeatureToggle } from '../../components/toggles';

const ProductComponent = ({ data, renderNbnDetailsComponent }: Props) => {
    const adaptedProps = adaptLexaData(data);
    if (!adaptedProps) return null;

    return (
        <FeatureToggle toggleName={'nbnFeature'}>
            {(isNbnSummaryToggleOn: boolean) =>
                isNbnSummaryToggleOn ? (
                    <View {...adaptedProps} renderNbnDetailsComponent={renderNbnDetailsComponent} />
                ) : null
            }
        </FeatureToggle>
    );
};

export default ProductComponent;

/* @flow */

import { compose3 as compose } from '../../../util/functional/compose';
import getFragmentData from '../../../util/getFragmentData';
import type { Channel as ChannelType } from '../../../models/Channel';
import type {
    GetBuyResidentialListing,
    GetRentResidentialListing,
    BuyResidentialListing,
    RentResidentialListing,
    Inspection,
} from '../../../models/lexa';
import InspectionsSummaryForBuy from './InspectionsSummaryForBuy.graphql';
import InspectionsSummaryForRent from './InspectionsSummaryForRent.graphql';

type RequiredProps = {
    inspections: Array<Inspection>,
};

const dataAdapter = ({ inspections }): ?string => {
    return inspections && inspections.length > 0 ? inspections[0].display.longLabel : null;
};

type GetBuyOrRentResidentialListing = GetRentResidentialListing | GetBuyResidentialListing;
type BuyOrRentResidentialListing = BuyResidentialListing | RentResidentialListing;

export default (channel: ChannelType) =>
    compose<GetBuyOrRentResidentialListing, BuyOrRentResidentialListing, RequiredProps, ?string>(
        dataAdapter,
        getFragmentData(channel === 'buy' ? InspectionsSummaryForBuy : InspectionsSummaryForRent),
        (data) => data.getListing()
    );

/* @flow */
import { SlideNames, type SlideName } from '../../../media-viewer';
import type { Button } from '../types';
import type { MediaIconClickType } from '../../../../client/tracking/event/types';

export default (
        openMediaViewer: (name: SlideName) => void,
        floorplansToursIconClick: (type: MediaIconClickType) => void
    ) =>
    (): Button => ({
        type: 'Button',
        className: 'floorplan',
        display: 'Floorplan',
        action: () => {
            openMediaViewer(SlideNames.FLOORPLAN);
            floorplansToursIconClick('floorplan');
        },
    });

/* @flow */
import type { Link } from '../types';
import type { ExternalLink } from '../../../../models/lexa';
import type { MediaIconClickType } from '../../../../client/tracking/event/types';

export default (floorplansToursIconClick: (type: MediaIconClickType) => void) =>
    (externalLink: ExternalLink): Link => ({
        type: 'Link',
        className: 'external',
        display: 'External Link',
        href: externalLink.href,
        action: () => floorplansToursIconClick('external_link'),
    });

import { Small, WifiLg, paletteHelper, spacingHelper } from '@rea-group/construct-kit-core';
import styled from 'styled-components';

export const StyledGrid = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    border: 0.0625rem solid ${paletteHelper('borderSecondary')};
    border-radius: 0.5rem;
    & > div:nth-child(2) {
        border-left: 0.0625rem solid ${paletteHelper('borderSecondary')};
    }
`;

export const StyledCard = styled.div`
    padding: ${spacingHelper('medium')};
`;

export const Body = styled(Small)`
    color: ${paletteHelper('textSecondary')};
`;

export const StyledIcon = styled(WifiLg)`
    color: ${paletteHelper('textSuccess')};
`;

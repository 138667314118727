/* @flow */
import React from 'react';
import HighlightVideoThumbnail from './highlight-video-thumbnail';
import type { Thumbnail } from './types';
import SectionHeading from '../section-heading';

type Props = {
    thumbnail: Thumbnail | null,
};

const View = ({ thumbnail }: Props) => {
    return thumbnail ? (
        <div>
            <SectionHeading>Property video</SectionHeading>
            <HighlightVideoThumbnail {...thumbnail} />
        </div>
    ) : null;
};

export default View;

/* @flow */
import React, { useContext } from 'react';
import adaptChildListingData from './adaptChildListingData';
import adaptHomeDesignsData from './adaptHomeDesignData';
import adaptRelatedPropertiesData from './adaptRelatedPropertiesData';
import type { GetResidentialListing, GetBuyResidentialListing, GetRelatedProperties } from '../../../models/lexa';
import { HomeDesignPrimaryFeatures, ResidentialPrimaryFeatures } from '../../primary-features';
import { Branding } from '../../branding';
import Card from './card';
import ChannelContext from '../../../util/ChannelContext';
import './styles.scss';

type DataType = {
    data: GetBuyResidentialListing,
};

type RelatedPropertiesType = {
    data: GetResidentialListing & GetRelatedProperties,
};

export const SiblingsCarousel = ({ data }: DataType) => {
    const adaptedData = adaptChildListingData(data);
    if (adaptedData) {
        return (
            <div className="footer-carousel-container">
                <div className="footer-carousel">
                    {adaptedData.map((item) => {
                        const primaryFeature = (
                            <ResidentialPrimaryFeatures
                                data={item.listing}
                                className="footer-carousel-card__primary-features"
                            />
                        );
                        return (
                            <Card
                                price={item.price}
                                templatedUrl={item.templatedUrl}
                                title={item.title}
                                url={item.url}
                                propertyTypeDisplay={item.propertyTypeDisplay}
                                key={item.id}
                                primaryFeature={primaryFeature}
                            />
                        );
                    })}
                </div>
            </div>
        );
    }
    return null;
};

export const HomeDesignCarousel = ({ data }: DataType) => {
    const adaptedData = adaptHomeDesignsData(data);
    if (adaptedData) {
        return (
            <div className="footer-carousel-container">
                <div className="footer-carousel">
                    {adaptedData.map((item) => {
                        const primaryFeature = (
                            <HomeDesignPrimaryFeatures
                                data={item.listing}
                                className="footer-carousel-card__primary-features"
                            />
                        );
                        return (
                            <Card
                                price={item.price}
                                templatedUrl={item.templatedUrl}
                                title={item.title}
                                url={item.url}
                                propertyTypeDisplay={item.propertyTypeDisplay}
                                key={item.id}
                                primaryFeature={primaryFeature}
                            />
                        );
                    })}
                </div>
            </div>
        );
    }
    return null;
};

export const RelatedPropertiesCarousel = ({ data }: RelatedPropertiesType) => {
    const channel = useContext(ChannelContext);
    const adaptedData = adaptRelatedPropertiesData(channel)(data);
    if (adaptedData) {
        const { items, shouldRenderBranding } = adaptedData;

        return (
            <div className="footer-carousel-container">
                <div className="footer-carousel">
                    {items.map((item) => {
                        const primaryFeature = (
                            <ResidentialPrimaryFeatures
                                data={item.listing}
                                className="footer-carousel-card__primary-features"
                            />
                        );
                        return (
                            <Card
                                price={item.price}
                                templatedUrl={item.templatedUrl}
                                title={item.title}
                                url={item.url}
                                propertyTypeDisplay={item.propertyTypeDisplay}
                                branding={
                                    shouldRenderBranding ? (
                                        <Branding data={data} styleModifier="small-non-condensed" />
                                    ) : null
                                }
                                key={item.id}
                                primaryFeature={primaryFeature}
                            />
                        );
                    })}
                </div>
            </div>
        );
    }
    return null;
};

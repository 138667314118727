/* @flow */
import React, { useContext } from 'react';
import adaptLexaData from './adaptLexaData';
import View from './View';
import type { ResidentialDetailsGetters } from '../../../models/lexa';
import MediaViewerContext from '../../media-viewer/provider/MediaViewerContext';

type Props = {
    data: ResidentialDetailsGetters,
};

const HighlightVideo = ({ data }: Props) => {
    const { openMediaViewer } = useContext(MediaViewerContext);
    return <View {...adaptLexaData(openMediaViewer)(data)} />;
};

export default HighlightVideo;

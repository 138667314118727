/* @flow */

import React from 'react';
import ScrollToElement from '../ScrollToElement';
import { Button, P } from '@rea-group/construct-kit-core';

type Props = {
    inspectionTime: string,
    inspectionTimesEl: () => ?Element,
};

const InspectionTime = ({ onClick }) => {
    return (
        <Button variant="link-primary" onClick={onClick}>
            View all
        </Button>
    );
};

export default ({ inspectionTime, inspectionTimesEl }: Props) => (
    <P>
        {inspectionTime}&nbsp;
        <ScrollToElement elementRef={inspectionTimesEl}>
            {(scrollOnClick) => <InspectionTime onClick={scrollOnClick} />}
        </ScrollToElement>
    </P>
);

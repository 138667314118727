import React from 'react';
import { ViewProps } from './types';
import { H3, Stack, Text, ArrowRightMd, Button, Inline } from '@rea-group/construct-kit-core';
import { AmazingSpeedDial, GoodSpeedDial, LogoIcon, UnavailableWifiIcon } from './NbnIcons';
import { ConnectionStatusIcon, SpeedIcon } from '../../../types';
import { Body, StyledIcon, StyledCard, StyledGrid } from './styles';
import { useBoolean } from '../../hooks';

const connectionIcons: { [Property in ConnectionStatusIcon]: React.ReactNode } = {
    available: <StyledIcon />,
    unavailable: <UnavailableWifiIcon />,
};

const speedIcons: { [Property in SpeedIcon]: React.ReactNode } = {
    amazing: <AmazingSpeedDial />,
    good: <GoodSpeedDial />,
};

export default function View(props: ViewProps) {
    const { title, connectionDescription, speedDescription, learnMore, renderNbnDetailsComponent } = props;
    const [isLearnMoreOpen, openLearnMore, closeLearnMore] = useBoolean(false);

    return (
        <Stack gap={'medium'}>
            <Inline grow={false} justifyContent="space-between">
                <H3>{title}</H3>
                <LogoIcon />
            </Inline>
            <StyledGrid>
                <StyledCard>
                    {connectionIcons[connectionDescription.connectionStatusIcon]}
                    <Text variant="subtitle01">{connectionDescription.title}</Text>
                    <Body>{connectionDescription.display}</Body>
                </StyledCard>
                {speedDescription && (
                    <StyledCard>
                        {speedIcons[speedDescription.speedIcon]}
                        <Text variant="subtitle01">{speedDescription.title}</Text>
                        <Body>{speedDescription.display}</Body>
                    </StyledCard>
                )}
            </StyledGrid>
            {learnMore && (
                <Button icon={<ArrowRightMd />} iconPlacement="right" variant="link-primary" onClick={openLearnMore}>
                    {learnMore.title}
                </Button>
            )}
            {isLearnMoreOpen && renderNbnDetailsComponent(closeLearnMore)}
        </Stack>
    );
}

/* @flow */
import './styles.scss';
import React from 'react';
import styled from 'styled-components';
import type { AdaptedPropertyFeature, AdaptedProps } from './types';
import ExpandoCollapso from '../../expando-collapso';
import SectionHeading from '../section-heading';
import BrowserOnly from '../../browser-only';

const PropertyFeaturesIcon = React.lazy(
    () => import(/* webpackChunkName: "property-features-icon" */ './PropertyFeaturesIcon')
);

const buttonText = (numFeatures) => (isOpen) => (isOpen ? 'Show fewer' : `Show more features (${numFeatures})`);
const MAX_FEATURES = 4;

const FallBackIcon = styled.div`
    margin-left: 1.5rem;
`;
const LazyPropertyFeaturesIcon = ({ id }) => (
    <BrowserOnly name="property-features-icon" fallback={<FallBackIcon />}>
        <PropertyFeaturesIcon id={id} />
    </BrowserOnly>
);
const featureDisplay = ({ id, displayLabel, value }: AdaptedPropertyFeature) => {
    const label = value ? `${displayLabel}: ` : displayLabel;
    return (
        <div key={id} className="property-features__feature">
            <LazyPropertyFeaturesIcon id={id} />
            <p>
                {label}
                {value && <span className="property-features__value">{value}</span>}
            </p>
        </div>
    );
};

const PropertyFeatures = ({ features }: AdaptedProps) => {
    if (features.length === 0) return null;
    return (
        <div className="property-features">
            <SectionHeading>Property features</SectionHeading>
            <div className="property-features__section">{features.slice(0, MAX_FEATURES).map(featureDisplay)}</div>

            {features.length > MAX_FEATURES && (
                <ExpandoCollapso
                    className="property-features__section"
                    buttonText={buttonText(features.length - MAX_FEATURES)}
                >
                    {features.slice(MAX_FEATURES).map(featureDisplay)}
                </ExpandoCollapso>
            )}
        </div>
    );
};

export default PropertyFeatures;

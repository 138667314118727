/* @flow */
import React, { useContext } from 'react';
import adaptLexaData from './adaptLexaData';
import View from './View';
import type { GetBuyResidentialListing, GetRentResidentialListing } from '../../../models/lexa/getListing';
import ChannelContext from '../../../util/ChannelContext';

type DataType = {|
    data: GetBuyResidentialListing | GetRentResidentialListing,
    inspectionTimesEl: () => ?Element,
|};

export default ({ data, inspectionTimesEl }: DataType) => {
    const channel = useContext(ChannelContext);
    const inspectionsText = adaptLexaData(channel)(data);
    if (inspectionsText) {
        return <View inspectionTime={inspectionsText} inspectionTimesEl={inspectionTimesEl} />;
    }
    return null;
};

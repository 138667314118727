/* @flow */

import { compose3 as compose } from '../../../util/functional/compose';
import getFragmentData from '../../../util/getFragmentData';
import type { GetBuyResidentialListing, BuyResidentialListing, HomeDesign } from '../../../models/lexa';
import type { CardItem } from './types';
import HomeDesignsCarousel from './HomeDesignsCarousel.graphql';

type AdaptedProps = Array<CardItem<HomeDesign>>;

const CID = 'nhhdp|buy:pdp:footer-homedesign';
const appendWithCid = (href) => href.replace('{cid}', CID);
const dataAdapter = ({ listingCompany }: BuyResidentialListing): ?AdaptedProps => {
    if (listingCompany && listingCompany.__typename === 'Builder') {
        const { homeDesigns } = listingCompany;
        if (homeDesigns && homeDesigns.designs.length > 1) {
            return homeDesigns.designs.slice(0, 3).map((design) => {
                const { id, _links, media, price = '', name = '' } = design;
                return {
                    id,
                    url: appendWithCid(_links.canonical.href),
                    templatedUrl: media.mainImage.templatedUrl,
                    price,
                    title: name,
                    propertyTypeDisplay: `By ${listingCompany.name}`,
                    listing: design,
                };
            });
        }
    }

    return null;
};

export default compose<GetBuyResidentialListing, BuyResidentialListing, BuyResidentialListing, ?AdaptedProps>(
    dataAdapter,
    getFragmentData(HomeDesignsCarousel),
    (data: GetBuyResidentialListing) => data.getListing()
);

/* @flow */
import { compose3 as compose } from '../../util/functional/compose';
import getFragmentData from '../../util/getFragmentData';
import type { GetResidentialListing, ListingCompany, ResidentialListing } from '../../models/lexa';
import type { Channel } from '../../models/Channel';
import type { Props as ViewProps } from './View';
import RelatedPropertiesFooter from './RelatedPropertiesFooter.graphql';

type RequiredProps = {
    listingCompany: ?ListingCompany,
};

const dataAdapter =
    (channel: Channel) =>
    ({ listingCompany }: RequiredProps): ?ViewProps => {
        if (listingCompany) {
            const cid = `nhbpp|${channel}:pdp:footer-overview`;
            return {
                name: `More properties from ${listingCompany.name}`,
                description: `These properties from ${listingCompany.name} are shown based on the property type and distance to this listing.`,
                href: listingCompany._links.canonical.href.replace('{cid}', cid),
            };
        }
        return null;
    };

export default (channel: Channel) =>
    compose<GetResidentialListing, ResidentialListing, RequiredProps, ?ViewProps>(
        dataAdapter(channel),
        getFragmentData(RelatedPropertiesFooter),
        (data) => data.getListing()
    );

import React from 'react';
import { Props } from './types';
import adaptLexaData from './adaptLexaData';
import View from './View';

export default function ProductComponent({ data, onClose }: Props) {
    const adaptedData = adaptLexaData(data);

    if (!adaptedData) return null;
    return <View {...adaptedData} onClose={onClose} />;
}

// import { GetComponentGetters } from '../../../types';
import compose from '../../util/functional/typescript/compose';
import { mockNbnDetails } from './mockNbnDetails';
import { AdaptedProps, NbnDetails } from './types';

const dataAdapter = ({ component }: { component: NbnDetails | null }): AdaptedProps => {
    if (!component) return null;
    return component;
};

// const adaptLexaData = compose(dataAdapter, (data: GetComponentGetters) => ({
//     component: data.getComponent('NbnDetailsComponent'),
// }));
const adaptLexaData = compose(dataAdapter, () => ({
    component: mockNbnDetails,
}));

export default adaptLexaData;
